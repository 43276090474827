<template>
    <b-modal
        id="modal-marketing"
        ref="modal"
        body-bg-variant="dark"
        centered
        footer-bg-variant="dark"
        header-bg-variant="dark"
        hide-header-close
        :title="modalTitle"
        @show="resetModal"
        @hidden="resetModal"
    >
        <b-form
            ref="form"
            @submit.stop.prevent="handleSubmit"
        >
            <b-form-group
                label="Email"
                label-for="email-input"
                :state="emailState"
            >
                <b-form-input
                    id="email-input"
                    v-model="email"
                    :state="emailState"
                    aria-describedby="input-live-feedback"
                    placeholder="Enter your email"
                    trim
                />

                <b-form-invalid-feedback id="input-live-feedback">
                    {{ invalidFeedback }}
                </b-form-invalid-feedback>
            </b-form-group>
        </b-form>

        <div class="text-center">
            <font-awesome-icon :icon="[ 'fas', 'envelope' ]" class="fa-2x"/>
        </div>

        <template v-slot:modal-footer>
            <div class="w-100">
                <b-button
                    class="float-right"
                    variant="secondary"
                    @click="handleOk"
                >
                    Sign up
                </b-button>

                <b-button
                    class="float-right mr-2"
                    variant="secondary"
                    @click="close"
                >
                    Close
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import {mapActions} from "vuex";

    import {library} from "@fortawesome/fontawesome-svg-core";
    import {
        faEnvelope
    } from '@fortawesome/free-solid-svg-icons';
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import Vue from "vue";

    library.add(faEnvelope);

    Vue.component('font-awesome-icon', FontAwesomeIcon);

    export default {
        name: 'MailchimpModal',
        props: {
            modalTitle: {
                type: String,
                default: '',
                required: true,
            },
            type: {
                type: String,
                default: '',
                required: true,
            }
        },
        data() {
            return {
                email: '',
                emailState: false,
                invalidFeedback: ''
            }
        },
        methods: {
            resetModal() {
                this.email = '';
                this.emailState = null;
            },
            handleOk(bvModalEvt) {
                // Prevent modal from closing
                bvModalEvt.preventDefault();
                // Trigger submit handler
                this.handleSubmit();
            },
            async handleSubmit() {              
                try {
                    await this.createEmailSignup({email: this.email, type: this.type});
                    
                    this.$bvModal.hide('modal-marketing');

                    this.$bvToast.toast('Successfully signed up!', {
                        autoHideDelay: 7000,
                        variant: 'success',
                        noCloseButton: true,
                        solid: true,
                        toaster: 'b-toaster-bottom-right'
                    });
                } catch(e) {
                    if (e.response.status === 400) {
                        this.emailState = false;
                        this.invalidFeedback = e.response.data.email[0];
                    } else {
                        this.$bvModal.hide('modal-marketing');

                        this.$bvToast.toast('Error signing up, please try again.', {
                            autoHideDelay: 9000,
                            variant: 'danger',
                            noCloseButton: true,
                            solid: true,
                            toaster: 'b-toaster-bottom-right'
                        });
                    }
                }
            },
            close() {
                this.$bvModal.hide('modal-marketing');
            },
            ...mapActions([
                'createEmailSignup',
            ])
        }
    }
</script>

<style lang="sass" scoped>
    @import 'src/custom.scss'

    .fa-2x
        color: $ga-golden

    input
        color: $so-silver !important
</style>
