import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Info/Home.vue'
import Login from './views/Login'
import Logout from './views/Logout'


Vue.use(VueRouter);

const requireLoggedIn = (to, from, next) => {
    if (!localStorage.getItem('LOGGED_IN')) {
      next('/login');
    } else {
      next();
    }
};

const routes = [
  {
    path: '*',
    name: 'Catch All',
    component: Home
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ './views/Info/About.vue')
  },
  {
    path: '/blog-home',
    name: 'BlogHome',
    component: () => import(/* webpackChunkName: "about" */ './views/Blog/BlogHome.vue')
  },
  {
    path: '/edit-post',
    name: 'EditPost',
    component: () => import(/* webpackChunkName: "about" */ './views/Blog/EditPost.vue'),
    props(route) {
      return {
        postId: route.query.postId
      }
    },
    beforeEnter: requireLoggedIn
  },
  {
    path: '/extras',
    name: 'Extras',
    component: () => import(/* webpackChunkName: "about" */ './views/Info/Extras.vue')
  },
  {
    path: '/ftf',
    name: 'Fan the Flame',
    component: () => import(/* webpackChunkName: "about" */ './views/Info/FanTheFlame.vue')
  },
  // {
  //   path: '/insta',
  //   name: 'Insta',
  //   component: () => import(/* webpackChunkName: "about" */ './views/Insta.vue')
  // },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    beforeEnter: requireLoggedIn
  },
  {
    path: '/new-post',
    name: 'NewPost',
    component: () => import(/* webpackChunkName: "about" */ './views/Blog/NewBlogPost.vue'),
    beforeEnter: requireLoggedIn
  },
  {
    path: '/post-detail',
    name: 'PostDetail',
    component: () => import(/* webpackChunkName: "about" */ './views/Blog/PostDetail.vue'),
    props(route) {
      return {
        postId: route.query.postId
      }
    }
  },
  {
    path: '/rfr',
    name: 'Rise from Ruin',
    component: () => import(/* webpackChunkName: "about" */ './views/Info/RiseFromRuin.vue')
  },
  {
    path: '/war',
    name: 'Wind and Reign',
    component: () => import(/* webpackChunkName: "about" */ './views/Info/WindAndReign.vue')
  },
  {
    path: '/woe',
    name: 'War of Earth',
    component: () => import(/* webpackChunkName: "about" */ './views/Info/WarOfEarth.vue')
  },
  {
    path: '/wow',
    name: 'Walk on Water',
    component: () => import(/* webpackChunkName: "about" */ './views/Info/WalkOnWater.vue')
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior () {
    return {x: 0, y: 0}
  }
});

export default router
