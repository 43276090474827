import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';
import axios from 'axios';
import router from "./router";

Vue.use(Vuex);

let blogURL = process.env.VUE_APP_API_URL + 'blog/';
const freeCopySignupURL = process.env.VUE_APP_API_URL + 'free-copy-signup/';
const signupURL = process.env.VUE_APP_API_URL + 'signup/';

export const getters = {
  customToolbar() {
    return [
      [{header: "1"}, {header: "2"}],
      ["bold", "italic", "underline", "strike"],
      ["align", {align: "center"}, {align: "right"}],
      [{ list: "ordered" }, { list: "bullet" }],
      ["blockquote", "code-block", "link"]
    ];
  }
};

export const actions = {
  createBlogPost({dispatch}, data){
    try {
      axios.post(blogURL, {
        title: data.title,
        body: data.body,
      },
      {
        headers: {
          Authorization: `Token ${localStorage.getItem('AUTH_TOKEN')}`
        }
      }).then(()=>{
        dispatch('fetchAllBlogPosts');
        router.push({name: 'BlogHome'});
      })
    } catch (e) {
      console.log(e);
    }
  },
  async createEmailSignup(context, {email, type}){
    return new Promise((resolve, reject) => {
      axios.post(
        type === 'subscribe' ? signupURL : freeCopySignupURL,
        {email: email}
      ).then((response) => {
          resolve(response)
      }).catch((error) => {
          reject(error)
      });
    })
  },
  deleteBlogPost({dispatch}, postURL){
    try {
      axios.delete(postURL, {
        headers: {
          Authorization: `Token ${localStorage.getItem('AUTH_TOKEN')}`
        }
      }).then(()=>{
        dispatch('fetchAllBlogPosts');
      })
    } catch (e) {
      console.log(e);
    }
  },
  fetchAllBlogPosts({commit}, page = '1'){
    try {
      axios.get(page ? blogURL + `?page=${page}` : blogURL)
        .then((res)=>{
          commit('SET_DATA', {field: 'blogPosts', data: res.data.results});
          commit('SET_DATA', {field: 'totalBlogPosts', data: res.data.count});
        })
    } catch (e) {
      console.log(e);
    }
  },
  async fetchInstaFeed({commit}) {
    const date = new Date().getDate();

    if (date === 1) {
      try {
        axios.post(process.env.VUE_APP_API_URL + 'insta-refresh/', {access_token: 'x'})
      } catch (e) {
        console.log(e);
      }
    }

    try {
      axios.get(process.env.VUE_APP_API_URL + 'instagram/')
        .then((res)=>{
          commit('SET_DATA', {field: 'instaFeed', data: res.data.data});
        })
    } catch (e) {
      console.log(e);
    }
  },
  loginUser({commit}, credentials) {
      commit('SET_DATA', {field: 'correctCreds', data: true});

      axios.post(process.env.VUE_APP_API_URL + 'rest-auth/login/', {
          username: credentials.username,
          password: credentials.password,
        }).catch(function (error) {
            if (error.response.status === 400) {
              commit('SET_DATA', {field: 'correctCreds', data: false});
            }
        }).then(response => {
            localStorage.setItem('AUTH_TOKEN', response.data.key);
            localStorage.setItem('LOGGED_IN', true);

            commit('SET_DATA', {field: 'correctCreds', data: true});
            commit('SET_DATA', {field: 'loggedIn', data: true});

            router.push({name: 'BlogHome'});
        });
  },
  logoutUser({commit}) {
    try {
      axios.post(process.env.VUE_APP_API_URL + 'rest-auth/logout/')
        .then(() => {
              localStorage.removeItem('AUTH_TOKEN');
              localStorage.removeItem('LOGGED_IN');

              commit('SET_DATA', {field: 'loggedIn', data: false});

              sessionStorage.clear();
            }
        )
    } catch (e) {
      console.log(e);
    }
  },
  updateBlogPost({dispatch}, post) {
    try {
      axios.put(post.url, {
            title: post.title,
            body: post.body,
          },
          {
            headers: {
              Authorization: `Token ${localStorage.getItem('AUTH_TOKEN')}`
            }
          }).then(()=>{
            dispatch('fetchAllBlogPosts');
            router.push({name: 'BlogHome'});
      })
    } catch (e) {
      console.log(e);
    }

    return true;
  },
};

export const mutations = {
  SET_DATA(state, {field, data}) {
    state[field] = data;
  },
};

export const state = () => {
  return {
    blogPosts: {},
    correctCreds: true,
    instaFeed: [],
    loggedIn: false,
    postsPerPage: 5,
    totalBlogPosts: 0,
  };
};

export default new Vuex.Store({
  plugins: [persistedState({
    storage: window.sessionStorage,
  })],
  state: state(),
  actions,
  getters,
  mutations,
});
