<template>
    <b-container>
        <h1 class="text-center">Welcome to the World of September Thomas!</h1>

        <h5 class="mt-3 text-center">Delight in the Dangerous: Fantasy Storyteller</h5>

        <div>
            <p class="text-center">
                Here you’ll find info on upcoming releases. Don’t forget to subscribe below and be the first to receive
                updates and announcements.
            </p>
        </div>

        <div>
            <p class="text-center">
                Sign up for monthly book updates and receive free gifts!
            </p>
        </div>

        <div class="text-center">
            <b-button
                variant="primary"
                @click="openModal('notify-me')"
            >
                <FontAwesomeIcon
                    class="mr-1"
                    icon="bell"
                />
                Notify Me
            </b-button>
        </div>

        <div class="mt-3">
            <b-carousel
                id="carousel-1"
                :interval="0"
                controls
                background="#495159"
            >
                <CarouselSlide
                    slide-book="RFR"
                />

                <CarouselSlide
                    slide-book="WOE"
                />

                <CarouselSlide
                    slide-book="WAR"
                />

                <CarouselSlide
                    slide-book="FTF"
                />

                <CarouselSlide
                    slide-book="WOW"
                />
            </b-carousel>
        </div>

        <div class="mt-3">
            <h2 class="text-center">EXCITING FREE PREQUEL</h2>
            <div>
                <p class="text-center">
                    <strong>
                        Before Zara discovered her power.<br>
                        Before Zara realized her divine fate.<br>
                        Before Geoffrey tried to make amends.<br>
                    </strong>
                </p>

                <p class="text-center">
                    There once stood four Elemental temples, each holding strong and steady throughout the course of time.
                </p>

                <p class="text-center">
                    Finn is one of the few kelpies to survive a ruthless and relentless persecution by other fey. He
                    spent most of his life searching for a place to belong, and he finally found that in the arms of the
                    Water Temple. As a vice admiral of its navy, with a black Water brand bold and sharp on his neck,
                    Finn understands his loyalty to his people and to his God.
                </p>

                <p class="text-center">
                    But one day, Order ships appear unannounced on the horizon, and everything Finn thought he knew will
                    be sent into a tailspin. His devotion is put to the test—in both the names of war and love.
                </p>
            </div>

            <div>
                <p class="text-center">
                    Read the prequel short story to the Elemental Gods series now.
                </p>
            </div>

            <div class="text-center">
                <b-button
                    variant="primary"
                    @click="openModal('free-copy')"
                >
                    Get Your Copy
                </b-button>
            </div>
        </div>

        <div class="pt-5 container">
            <b-row>
                <MailchimpModal
                    :modalTitle="modalTitle"
                    :type="type"
                />
            </b-row>
        </div>
    </b-container>
</template>

<script>
    import CarouselSlide from '../../components/CarouselSlide';
    import MailchimpModal from '../../components/MailchimpModal';
    import {mapActions} from 'vuex';

    import {library} from '@fortawesome/fontawesome-svg-core';
    import {
        faBell
    } from '@fortawesome/free-solid-svg-icons';
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import Vue from 'vue';

    library.add(faBell);

    Vue.component('font-awesome-icon', FontAwesomeIcon);

    export default {
        name: 'Home',
        components: {
            CarouselSlide,
            FontAwesomeIcon,
            MailchimpModal,
        },
        data() {
            return {
                limit: 24,
                modalTitle: '',
                type: '',
            }
        },
        async created() {
            this.fetchAllBlogPosts();
            // await this.fetchInstaFeed(this.limit);
        },
        methods: {
            openModal(modal) {
                if (modal === 'notify-me') {
                    this.modalTitle = 'Get Email Notifications!';
                    this.type = 'subscribe';
                } else {
                    this.modalTitle = 'Get your Free Copy!';
                    this.type = 'free-copy';
                }

                this.$bvModal.show('modal-marketing')
            },
            ...mapActions([
                'fetchAllBlogPosts',
                'fetchInstaFeed',
            ])
        }
    }
</script>

<style
    lang="sass"
    scoped
>
    @import '../../custom'

    h4
        color: $ga-golden

    h2
        color: $so-silver !important
</style>
