<template>
    <b-navbar
        toggleable="lg"
        type="dark"
        variant="primary"
    >
        <router-link to="/">
            <b-navbar-brand href="/">September Thomas</b-navbar-brand>
        </router-link>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <router-link
                    class="routerLink"
                    to="/"
                >
                    <b-nav-item href="/">Welcome</b-nav-item>
                </router-link>

                <b-nav-item-dropdown
                    text="Elemental Gods"
                    right
                >
                    <b-dropdown-item
                        class="routerLink"
                        @click="navigate('Walk on Water')"
                    >
                        <font-awesome-icon :icon="[ 'fas', 'water' ]" class="mr-1 mr-lg-0"/>
                        Walk on Water
                    </b-dropdown-item>

                    <b-dropdown-item
                        class="routerLink"
                        @click="navigate('Fan the Flame')"
                    >
                        <font-awesome-icon :icon="[ 'fas', 'fire' ]" class="mr-2 mr-lg-0"/>
                        Fan the Flame
                    </b-dropdown-item>

                    <b-dropdown-item
                        class="routerLink"
                        @click="navigate('Wind and Reign')"
                    >
                        <font-awesome-icon :icon="[ 'fas', 'wind' ]" class="mr-1 mr-lg-0"/>
                        Wind and Reign
                    </b-dropdown-item>

                    <b-dropdown-item
                        class="routerLink"
                        @click="navigate('War of Earth')"
                    >
                        <font-awesome-icon :icon="[ 'fas', 'globe-americas' ]" class="mr-1 mr-lg-0"/>
                        War of Earth
                    </b-dropdown-item>

                    <b-dropdown-item
                        class="routerLink"
                        @click="navigate('Rise from Ruin')"
                    >
                        <font-awesome-icon :icon="[ 'fas', 'angle-double-up' ]" class="mr-2 mr-lg-0"/>
                        Rise from Ruin
                    </b-dropdown-item>

                    <b-dropdown-item
                        class="routerLink"
                        @click="navigate('Extras')"
                    >
                        <font-awesome-icon :icon="[ 'fas', 'plus' ]" class="mr-1 mr-lg-0"/>
                        Extras
                    </b-dropdown-item>
                </b-nav-item-dropdown>

                <router-link
                    class="routerLink"
                    to="/about"
                >
                    <b-nav-item href="/about">Author</b-nav-item>
                </router-link>

                <router-link
                    class="routerLink"
                    to="/blog-home"
                >
                    <b-nav-item href="/blog-home">Blog</b-nav-item>
                </router-link>

<!--                <router-link-->
<!--                        class="routerLink"-->
<!--                        to="/insta"-->
<!--                >-->
<!--                    <b-nav-item href="/insta">Insta</b-nav-item>-->
<!--                </router-link>-->
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="socials ml-auto">
                <b-nav-item
                    href="https://www.instagram.com/september.thomas/"
                    target="_blank"
                >
                    <font-awesome-icon :icon="[ 'fab', 'instagram' ]" class="mr-3 mr-lg-0"/>
                </b-nav-item>

                <b-nav-item
                    href="https://www.pinterest.com/september_thomas/boards/"
                    target="_blank"
                >
                    <font-awesome-icon :icon="[ 'fab', 'pinterest' ]" class="mr-3 mr-lg-0"/>
                </b-nav-item>

                <b-nav-item
                    href="https://www.facebook.com/SeptemberThomasAuthor"
                    target="_blank"
                >
                    <font-awesome-icon :icon="[ 'fab', 'facebook' ]" class="mr-3 mr-lg-0"/>
                </b-nav-item>

                <b-nav-item
                    href="https://www.goodreads.com/user/show/104717577-september-thomas"
                    target="_blank"
                >
                    <font-awesome-icon :icon="[ 'fab', 'goodreads' ]" class="mr-3 mr-lg-0"/>
                </b-nav-item>

                <b-nav-item-dropdown
                    v-if="loggedIn"
                    right
                >
                    <b-dropdown-item
                        class="routerLink"
                        @click="navigate('NewPost')"
                    >
                        <font-awesome-icon :icon="[ 'fas', 'edit' ]" class="mr-1 mr-lg-0"/>
                        New Post
                    </b-dropdown-item>

                    <b-dropdown-item
                        class="routerLink"
                        @click="navigate('Logout')"
                    >
                        <font-awesome-icon :icon="[ 'fas', 'sign-out-alt' ]" class="mr-2 mr-lg-0"/>
                        Logout
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
    import {mapState} from 'vuex';

    import {library} from '@fortawesome/fontawesome-svg-core';
    import {
        faFacebook,
        faGoodreads,
        faInstagram,
        faPinterest
    } from '@fortawesome/free-brands-svg-icons';

    import {
        faAngleDoubleUp,
        faEdit,
        faFire,
        faGlobeAmericas,
        faPlus,
        faSignOutAlt,
        faWater,
        faWind,
    } from '@fortawesome/free-solid-svg-icons';

    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

    import Vue from "vue";

    library.add(
        faAngleDoubleUp,
        faEdit,
        faFacebook,
        faGlobeAmericas,
        faGoodreads,
        faInstagram,
        faPinterest,
        faPlus,
        faSignOutAlt,
        faWater,
        faWind,
        faFire
    );

    Vue.component('font-awesome-icon', FontAwesomeIcon);

    export default {
        name: 'NavBar',
        data() {
            return {
                permission: true,
            }
        },
        computed: {
            ...mapState([
                'loggedIn',
            ])
        },
        methods: {
            navigate(routeName) {
                this.$router.push({name: routeName});
            },
        }
    }
</script>

<style
    scoped
    lang="sass"
>
    a
        text-decoration: none

    .routerLink
        text-decoration: none

    .socials
        flex-direction: row !important

</style>
