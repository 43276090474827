<template>
    <BContainer class="mt-5" fluid>
        <b-row class="justify-content-center">
            <b-col cols="10" sm="4">
                <h3>Sign In</h3>

                <div class="form-group">
                    <label for="user">Username</label>
                    <input 
                        class="form-control form-control-lg" 
                        v-model="username"
                        id="user" 
                        name="username" 
                        type="text" 
                        @keydown.enter.prevent="loginSubmit"
                    >
                </div>

                <div class="form-group">
                    <label for="pass">Password</label>
                    <input 
                        class="form-control form-control-lg" 
                        v-model="password"
                        id="pass" 
                        name="password" 
                        type="password" 
                        @keydown.enter.prevent="loginSubmit"
                    >
                </div>

                <b-button
                    class="btn-lg btn-block"
                    :disabled="loading"
                    variant="dark"
                    @click="loginSubmit"
                >
                    <div v-if="loading">
                        <BImg
                            class="fa-beat fa-fw"
                            alt="Loading Logo"
                            fluid
                            src="https://banedev.s3.us-east-2.amazonaws.com/SeptemberThomas/TitleQuill.png"
                        />
                    </div>
                        
                    <div v-else>
                        Login
                    </div>
                </b-button>

            </b-col>
        </b-row>

        <b-row class="justify-content-center mt-5">
            <h2 class="text-danger" v-if="!correctCreds">Wrong Credentials! Gotta give it another shot!</h2>
        </b-row>
    </BContainer>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: 'login',
        data () {
            return {
                loading: false,
                password: '',
                username: '',
            }
        },
        created() {
            this.SET_DATA({field: 'correctCreds', data: true});
        },
        computed: {
            ...mapState([
                'correctCreds',
            ])
        },
        methods: {
            async loginSubmit () {
                this.loading = true;

                this.loginUser({
                    username: this.username,
                    password: this.password
                });

                this.loading = false;
            },
            ...mapActions([
                'loginUser'
            ]),
            ...mapMutations(['SET_DATA'])
        }
    }
</script>
