<template>
  <div id="app">
    <NavBar/>
    <main class="d-flex pt-3 flex-column min-vh-100">
      <router-view/>
      <Footer/>
    </main>
  </div>
</template>

<script>
  import Footer from './components/Footer';
  import NavBar from './components/NavBar';

  export default {
    name: 'Home',
    components: {
      Footer,
      NavBar,
    },
  }
</script>
