<template>
    <footer class="footer-distributed mt-auto">
        <div class="footer-left">
            <div>
                <a href="/"><h3>September Thomas</h3></a>
            </div>

            <div class="mt-3">
                <p class="footer-dev-name">Website Developed by David Bane</p>
            </div>

            <div class="mt-3">
                <p class="footer-links">
                    <b-navbar-nav class="order-links">
                        <router-link to="/wow">
                            <b-nav-item href="/wow">Elemental Gods</b-nav-item>
                        </router-link>
                        ·
                        <router-link to="/about">
                            <b-nav-item href="/about">Author</b-nav-item>
                        </router-link>
                        ·
                        <router-link to="/blog-home">
                            <b-nav-item href="/blog-home">Blog</b-nav-item>
                        </router-link>
<!--                        ·-->
<!--                        <router-link to="/insta">-->
<!--                            <b-nav-item href="/insta">Insta</b-nav-item>-->
<!--                        </router-link>-->
                    </b-navbar-nav>
                </p>
            </div>
        </div>

        <div class="footer-center mb-4">
            <div class="hor-center text-center">
                <div class="d-sm-block">
                    <font-awesome-icon :icon="[ 'fas', 'map-marker-alt' ]" class="fas"/>
                    <p><a href="http://maps.google.com/?q=Omaha, Nebraska" target="_blank">Omaha, Nebraska</a></p>
                </div>

                <div class="d-sm-block mt-2">
                    <font-awesome-icon :icon="[ 'fas', 'envelope' ]" class="fas"/>
                    <p><a href="mailto:septemberthomas@hotmail.com" target="_blank">septemberthomas@hotmail.com</a></p>
                </div>
            </div>
        </div>

        <div class="footer-right">
            <div class="hor-center">
                <p class="d-sm-block footer-company-about">
                    <span>SOCIAL MEDIA</span>
                    See what I'm up to by following me on social media!
                </p>

                <div class="footer-icons mt-3">
                    <a href="https://www.instagram.com/september.thomas/" target="_blank">
                        <font-awesome-icon :icon="[ 'fab', 'instagram' ]" class="fas"/>
                    </a>
                    <a href="https://www.pinterest.com/september_thomas/boards/" target="_blank">
                        <font-awesome-icon :icon="[ 'fab', 'pinterest' ]" class="fas"/>
                    </a>
                    <a href="https://www.facebook.com/SeptemberThomasAuthor" target="_blank">
                        <font-awesome-icon :icon="[ 'fab', 'facebook' ]" class="fas"/>
                    </a>
                    <a href="https://www.goodreads.com/user/show/104717577-september-thomas" target="_blank">
                        <font-awesome-icon :icon="[ 'fab', 'goodreads' ]" class="fas"/>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import {library} from "@fortawesome/fontawesome-svg-core";
    import {
        faEnvelope,
        faMapMarkerAlt
    } from '@fortawesome/free-solid-svg-icons';
    import {
        faFacebook,
        faGoodreads,
        faInstagram,
        faPinterest,
    } from "@fortawesome/free-brands-svg-icons";
    import Vue from "vue";
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

    library.add(
        faEnvelope,
        faFacebook,
        faGoodreads,
        faInstagram,
        faMapMarkerAlt,
        faPinterest,
    );

    Vue.component('font-awesome-icon', FontAwesomeIcon);

    export default {
        name: 'Footer'
    }
</script>

<style scoped>
    a {
        text-decoration: none !important;
    }

    .order-links {
        display: inline-block;
    }
</style>
