<template>
    <b-container>
        <b-row class="vh-100" align-v="center">
            <b-col cols="12" class="text-center">
                <h3 class="text-center">{{ logoutText }}</h3>

                <router-link
                    class="routerLink"
                    :to="{ path: '/login' }"
                >
                    <b-button
                        variant="primary"
                    >
                        Login
                    </b-button>
                </router-link>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import {mapActions} from 'vuex';

    export default {
        data() {
            return {
                logoutText: '',
                ttylPhrases: [
                    'See ya later Katie :)',
                    'Keep it up champ!',
                    'Great work today :)',
                    'Say hi to Zara for me!',
                    'Generic Log Out Success Message, bleh',
                    'Leaving already?!',
                    'Peace out bro',
                    'bum da bum bummm - name that song',
                    'shiver me timbers - see ya!',
                    'safe travels cowboy'
                ],
            };
        },
        created() {
            this.logoutUser();
            this.logoutText = this.ttylPhrases[Math.floor(Math.random() * this.ttylPhrases.length)];
        },
        methods:{
            ...mapActions([
                'logoutUser'
            ])
        }
    }
</script>
